import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/NewsSingle";

let News = ({ data, location }) => (
  <Layout location={location} postPage={true}>
    <SEO title={data.markdownRemark.frontmatter.title} />
    <Section news={data.markdownRemark.frontmatter} location={location} />
  </Layout>
);

export const query = graphql`query ($slug: String!) {
  markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    frontmatter {
      category
      link
      title
      sector_focus
      author
      date
      content
      thumbnail {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      feature_image {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
}
`;

export default News;
