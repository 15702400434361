import React, { Component } from "react";
import * as styles from "./index.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import { HtmlParser } from "../utils/helperFunctions";
import SocialIcon from "../utils/SocialIcon";

export default class News extends Component {
  render() {
    const { news, location } = this.props;
    return (
      <div>
        {news.thumbnail && (
          <header className={`${styles.header} relative`}>
            <div className={`${styles.bgContainer}`}>
              <GatsbyImage
                image={news.thumbnail.childImageSharp.gatsbyImageData}
                loading="eager"
                alt=""
              />
            </div>
          </header>
        )}
        <section className={`relative pb-40 mt-32`}>
          <div className={`${styles.sidebar} flex flex-col absolute lg:hidden`}>
            <div
              className={`${styles.socialIcon} my-6 relative cursor-pointer`}
            >
              <SocialIcon
                type="twitter"
                link={`http://www.twitter.com/share?url=${location.href}`}
              />
            </div>
            <div
              className={`${styles.socialIcon} my-6 relative cursor-pointer`}
            >
              <SocialIcon
                type="facebook"
                link={`https://www.facebook.com/sharer.php?u=${location.href}`}
              />
            </div>
            <div
              className={`${styles.socialIcon} my-6 relative cursor-pointer`}
            >
              <SocialIcon
                type="linkedin"
                link={`https://www.linkedin.com/sharing/share-offsite/?url=${location.href}`}
              />
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.category}>
              {typeof news.category === "object"
                ? news.category &&
                  news.category.map((item, index) =>
                    (index ? ", " : "") + item === "vectrviews"
                      ? "vectr views"
                      : item
                  )
                : news.category.includes("vectrviews")
                ? "vectr views"
                : news.category}
            </div>
            <h2 className={styles.title}>{news.title}</h2>
            <div className={styles.author}>{news.author}</div>
            <div className={`hidden lg:flex mt-16`}>
              <div className="w-12 mx-6">
                <SocialIcon
                  type="twitter"
                  link={`http://www.twitter.com/share?url=${location.href}`}
                />
              </div>
              <div className="w-12 mx-6">
                <SocialIcon
                  type="facebook"
                  link={`https://www.facebook.com/sharer.php?u=${location.href}`}
                />
              </div>
              <div className="w-12 mx-6">
                <SocialIcon
                  type="linkedin"
                  link={`https://www.linkedin.com/sharing/share-offsite/?url=${location.href}`}
                />
              </div>
            </div>
            <div className={`${styles.content} md-container`}>
              {news.content && <HtmlParser source={news.content} />}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
